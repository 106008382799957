<template>
	<Dialog :dialog="dialog" :dialog-width="820">
		<template v-slot:title> Add Project </template>
		<template v-slot:body>
			<v-row>
				<v-col sm="3" class="">
					<label for="">Customer Name</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined v-model="project.customer_name"> </v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">Company Name</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined v-model="project.company_name"> </v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">Start Date</label>
				</v-col>
				<v-col sm="3" class="">
					<DatePicker v-model="project.start_date"></DatePicker>
				</v-col>
				<v-col sm="3" class="">
					<label for="">Contract Duration</label>
				</v-col>
				<v-col sm="3" class="">
					<div class="d-flex">
						<v-text-field
							hide-details
							outlined
							type="number"
							placeholder="Duration"
							v-model="project.contract_duration"
						>
						</v-text-field>
						<v-autocomplete
							hide-details
							outlined
							:items="durationList"
							placeholder="Duration Type"
							v-model="contract_duration_type"
						></v-autocomplete>
					</div>
				</v-col>
				<v-col sm="3" class="">
					<label for="">End Date</label>
				</v-col>
				<v-col sm="3" class="">
					<DatePicker v-model="project.end_date"></DatePicker>
				</v-col>
				<v-col sm="3" class="">
					<label for="">Target URL</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined placeholder="Project URL" v-model="project.target_url">
					</v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">Google</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined placeholder="Google" v-model="project.google_url">
					</v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">No. of Keyword</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field
						hide-details
						outlined
						placeholder="No. of Keyword"
						type="number"
						v-model="project.google_url"
					>
					</v-text-field>
				</v-col>
				<v-col sm="12" class="">
					<h5>Project Traget</h5>
				</v-col>
				<v-col sm="3" class="">
					<label for="">3 Month Guarantee</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined v-model="project_target.three_month"> </v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">6 Month Guarantee</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined v-model="project_target.six_month"> </v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">9 Month Guarantee</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined v-model="project_target.nine_month"> </v-text-field>
				</v-col>
				<v-col sm="3" class="">
					<label for="">12 Month Guarantee</label>
				</v-col>
				<v-col sm="3" class="">
					<v-text-field hide-details outlined placeholder="" v-model="project_target.one_year">
					</v-text-field>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				color="blue darken-4 white--text"
				tile
				depressed
				class="mr-2"
				@click="$emit('close', true)"
			>
				Save
			</v-btn>
			<v-btn tile depressed @click="$emit('close', true)"> Cancel </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
export default {
	name: "seo-create-dialog",
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			seo_create_dialog: false,
			contract_duration_type: null,
			project: {
				customer_name: null,
				company_name: null,
				start_date: null,
				end_date: null,
				contract_duration: null,
				target_url: null,
				google: null,
				keywords: 22,
			},
			project_target: {
				three_month: null,
				six_month: null,
				nine_month: null,
				twelve_month: null,
			},
		};
	},
	components: {
		Dialog,
		DatePicker,
	},
};
</script>
