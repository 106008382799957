<template>
	<v-sheet class="recurring-main-listing position-relative" style="">
		<v-layout class="flex-wrap">
			<div class="pt-3 d-flex align-center justify-content-between w-100">
				<h4>Ranking</h4>
				<v-spacer></v-spacer>
				<div class="d-flex">
					<v-text-field
						outlined
						hide-details
						placeholder="Search Project..."
						v-model="invoice_number"
						class="w-10 mr-2"
					></v-text-field>
					<v-text-field
						outlined
						hide-details
						placeholder="From Date..."
						v-model="contact_person"
						class="w-10 mr-2"
					></v-text-field>
					<v-text-field
						outlined
						hide-details
						placeholder="To date..."
						v-model="notification"
						class="w-10 mr-2"
					></v-text-field>
					<v-select
						:items="projectStatusList"
						outlined
						hide-details
						class="w-10"
						v-model="projectStatus"
					>
						<template v-slot:selection="data">
							<v-chip v-bind="data.attrs" :input-value="data.selected" class="pl-0">
								<v-avatar class="mr-3" :color="data.item.color"> </v-avatar>
								{{ data.item.text }}
							</v-chip>
						</template>
						<template v-slot:item="data">
							<template>
								<v-list-item-avatar class="ma-0" width="30" min-width="30">
									<v-avatar size="20" :color="data.item.color"> </v-avatar>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-text v-html="data.item.text"></v-list-item-text>
								</v-list-item-content>
							</template>
						</template>
					</v-select>
					<v-btn color="blue darken-4 white--text" tile min-height="30" class="ml-3">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn>
					<v-btn
						color="blue darken-4 white--text"
						tile
						min-height="30"
						class="ml-3"
						@click="projectCreateDialog = true"
					>
						<v-icon color="white">mdi-plus</v-icon>
					</v-btn>
				</div>
			</div>
		</v-layout>
		<v-sheet class="recurring-main-listing position-relative" style="height: calc(100vh - 125px)">
			<v-layout class="flex-wrap" v-if="false">
				<div class="py-3 d-flex align-center justify-space-between w-100">
					<h4 class="fw-500 title-text mr-4" v-if="false" style="white-space: nowrap">Seo Executive</h4>
					<v-spacer></v-spacer>
				</div>
			</v-layout>
			<div class="my-2 overflow-auto">
				<span class="fw-500">Filters - </span>
				<v-btn-toggle v-model="toggle_exclusive" mandatory color="primary" dense tile>
					<v-btn class="blue--text btn-border"> ALL </v-btn>
					<v-btn
						class="btn-border"
						color="blue darken-4 white--text"
						v-for="(data, index) in filter"
						:key="index"
					>
						{{ data }}
					</v-btn>
				</v-btn-toggle>
			</div>
			<v-layout class="py-2 d-flex grey lighten-4 align-items-center">
				<div class="mx-4">
					<img :src="$assetURL('media/form-svg/singapore-flag.svg')" class="" width="30" />
				</div>
				<h4 class="mb-0">Google Singapore</h4>
			</v-layout>
			<v-layout>
				<v-row>
					<v-col class="border-right">
						<ChartCard
							label="Average Poisition"
							:total-count="40"
							:up-count="5"
							bg-color="pink lighten-5"
						></ChartCard>
					</v-col>
					<v-col class="border-right">
						<ChartCard
							label="Trafic Forcast"
							:total-count="40"
							:up-count="5"
							bg-color="blue lighten-5"
						></ChartCard>
					</v-col>
					<v-col class="border-right">
						<ChartCard
							label="Search Visibility"
							:total-count="40"
							:up-count="5"
							bg-color="orange lighten-5"
						></ChartCard>
					</v-col>
					<v-col class="border-right">
						<ChartCard
							label="SERP Feature"
							:total-count="40"
							:up-count="5"
							bg-color="red lighten-5"
						></ChartCard>
					</v-col>
					<v-col class="border-right">
						<ChartCard
							label="% in Top 10"
							:total-count="40"
							:up-count="5"
							bg-color="cyan lighten-5"
						></ChartCard>
					</v-col>
					<v-col class="border-right">
						<ChartCard
							label="Selected Keywords"
							keywords="products, seo, products, seo, software"
							bg-color="green lighten-5"
						></ChartCard>
					</v-col>
				</v-row>
			</v-layout>
			<div class="w-100">
				<apexchart
					type="line"
					height="350"
					width="100%"
					:options="chartOptions"
					:series="series"
				></apexchart>
			</div>
			<div class="v-data-table bt-table theme--light">
				<div class="v-data-table__wrapper table_height">
					<table class="w-100">
						<thead>
							<tr>
								<th width="40">
									<v-checkbox dense v-model="checkbox" hide-details class="m-0 p-0"></v-checkbox>
								</th>
								<th>Sr. No</th>
								<th>Project Name</th>
								<th>Company Name</th>
								<th>Sales owner</th>
								<th>KW</th>
								<th>Performance</th>
								<th>Top 10</th>
								<th>Top 5</th>
								<th>Dead URL</th>
								<th>Start</th>
								<th>End</th>
								<th class="text-center">Graph</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="i in 5" :key="i" @click="$router.push({ name: 'seo-detail' })">
								<td>
									<v-checkbox dense v-model="checkbox" hide-details class="m-0 p-0"></v-checkbox>
								</td>
								<td>{{ i + 1 }}</td>
								<td>
									Sample project
									<v-chip v-if="false" color="blue white--text" label>INV0002</v-chip>
								</td>
								<td>Bthrust pte ltd</td>
								<td>John</td>
								<td><v-chip color="blue white--text" label>30</v-chip></td>
								<td><v-chip color="green white--text" label>High</v-chip></td>
								<td><v-chip color="orange white--text" label>10</v-chip></td>
								<td><v-chip color="green darken-2 white--text" label>4</v-chip></td>
								<td>bthrustgrp.com</td>
								<td><b class="blue--text">17/08/2022</b></td>
								<td><b class="red--text">19/12/2022</b></td>
								<td class="text-center">
									<v-hover>
										<v-icon class="cursor-pointer">mdi-eye</v-icon>
									</v-hover>
								</td>
								<td>
									<v-icon size="20" class="mr-2 cursor-pointer" color="blue">mdi-pencil</v-icon>
									<v-icon size="20" class="cursor-pointer" color="red">mdi-delete</v-icon>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<v-pagination v-if="false" class="listing-pagination" :length="3" v-model="page"> </v-pagination>
		</v-sheet>
		<SeoProjectCreate
			:dialog="projectCreateDialog"
			@close="projectCreateDialog = false"
		></SeoProjectCreate>
	</v-sheet>
</template>
<script>
import SeoProjectCreate from "@/view/module/seo/SeoProjectCreate";
import ChartCard from "@/view/module/seo/ChartCard";
export default {
	name: "SeoExecutive",
	data() {
		return {
			dataTab: "seo_executive",
			page: 1,
			checkbox: false,
			projectCreateDialog: false,
			notification_on: "Select Notification On...",
			projectStatusList: [
				{ text: "All", value: 0, color: "blue" },
				{ text: "Pending", value: 1, color: "orange" },
				{ text: "completed", value: 2, color: "green" },
				{ text: "Cancle", value: 3, color: "red" },
			],
			projectStatus: 0,
			contact_person: null,
			invoice_number: null,
			notification: null,
			status: null,
			toggle_exclusive: null,
			filter: ["Top 1", "Top 5", "Top 10", "Top 20", "Top 30", "Top 50", "Top 100"],
			filter_prev: [
				"A",
				"B",
				"C",
				"D",
				"E",
				"F",
				"G",
				"H",
				"I",
				"J",
				"K",
				"L",
				"M",
				"N",
				"O",
				"P",
				"Q",
				"R",
				"S",
				"T",
				"U",
				"V",
				"W",
				"X",
				"Y",
				"Z",
			],

			series: [
				{
					name: "Desktops",
					data: [10, 12, 14],
				},
			],
			chartOptions: {
				chart: {
					height: 150,
					type: "line",
					zoom: {
						enabled: false,
					},
					legend: {
						show: false,
					},
				},
				dataLabels: {
					enabled: false,
				},
				stroke: {
					curve: "straight",
				},
				title: {
					text: "Current",
					align: "left",
				},
				tooltip: {
					enabled: false,
				},
				grid: {
					row: {
						colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
						opacity: 0.5,
					},
				},
				xaxis: {
					categories: ["2020", "2021", "2022", "2023"],
					labels: {
						show: true,
					},
				},
				yaxis: {
					show: true,
				},
			},
		};
	},
	components: {
		SeoProjectCreate,
		ChartCard,
	},
};
</script>
